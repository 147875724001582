/* eslint-disable */
import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import ourMission from "./../assets/images/our-mission-1.jpg"
import posterOne from "./../assets/images/poster-1.png"
import posterTwo from "./../assets/images/poster-2.png"
import "../styles/page-ourmission.scss"

export default () => (
  <Layout fluid={true} pageInfo={{ pageName: "Our Mission" }}>
    <SEO title="Our Mission" keywords={[`gatsby`, `react`, `bootstrap`]} />
    <Container fluid>
      <Container className="pb-5 pt-lg-5 pt-sm-0">
        <Row>
          <Col lg={5} md={12} className="center-block order-2 order-lg-1">
            <h1 className="head-text">Working for a safer world</h1>
            <p className="sub-text">
              During a fire, closing the door could have a potentially
              life-saving impact.
            </p>
          </Col>
          <Col
            lg={7}
            md={12}
            className="order-lg-2 order-1"
            data-sal="slide-left"
            data-sal-duration="800"
            data-sal-easing="ease"
          >
            <img className="rounded img-fluid" src={ourMission} />
          </Col>
        </Row>
      </Container>
      <Container className="about-us" fluid>
        <Container className="about-text pt-lg-5 pb-lg-5">
          <Row>
            <Col className="pt-5" md={{ span: 8, offset: 2 }}>
              <p>
                Underwriters Laboratories is a nonprofit organization dedicated
                to advancing the UL public safety mission of creating safer
                living and working environments for people everywhere through
                the discovery and application of scientific knowledge.
              </p>
              <br></br>
              <p>
                We conduct rigorous independent research and analyze safety
                data, convene experts worldwide to address risks, share
                knowledge through safety education and public outreach
                initiatives, and develop standards to guide safe
                commercialization of evolving technologies. We foster
                communities of safety, from grassroots initiatives for
                neighborhoods to summits of world leaders.
              </p>
              <br></br>
              <p>
                Our organization employs collaborative and scientific approaches
                with partners and stakeholders to drive innovation and progress
                toward improving safety, security, and sustainability,
                ultimately enhancing societal well-being.
              </p>
              <br></br>
              <p>
                Over a decade of research conducted by the{" "}
                <a href="http://FSRI.org/" className="hyperlink">
                  Fire Safety Research Institute (FSRI)
                </a>{" "}
                , part of UL Research Institutes, proved that a simple
                behavioral change, closing the door, could have potentially
                life-saving impact during a fire. Spreading this crucial fire
                safety message with first responders and the public has become
                an essential part of advancing the UL mission, working for a
                safer world.
              </p>
            </Col>
          </Row>
        </Container>
        <Container />
        <Container className={"video-example d-block pt-5 pb-5"} fluid>
          <Container>
            <Row className={"d-flex justify-content-center"}>
              <Col
                md={6}
                xs={12}
                className={"mb-5"}
                data-sal="slide-right"
                data-sal-duration="800"
                data-sal-delay="1500"
                data-sal-easing="ease"
              >
                <Row className={"title-wrapper text-left"}>
                  <Col md={9}>
                    <h3>
                      A striking visual comparison of natural and synthetic
                      furnishings.
                    </h3>
                  </Col>
                </Row>
                <Row className={"d-flex justify-lg-content-center"}>
                  <div className="m-2 embed-responsive embed-responsive-16by9">
                    <iframe
                      poster={posterOne}
                      className="rounded embed-responsive-item"
                      src="https://www.youtube.com/embed/87hAnxuh1g8"
                      allowFullScreen
                    ></iframe>
                  </div>
                </Row>
                <Row>
                  <Col md={12} className={"text-left px-lg-0"}>
                    <p className="caption">
                      See the impact that synthetic furnishings have in
                      time-to-flashover.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                md={6}
                xs={12}
                data-sal="slide-left"
                data-sal-duration="800"
                data-sal-delay="1500"
                data-sal-easing="ease"
              >
                <Row className={"title-wrapper text-left"}>
                  <Col md={9}>
                    <h3>
                      A comparison of an open door bedroom and a closed door
                      bedroom in a fire.
                    </h3>
                  </Col>
                </Row>
                <Row className={"d-flex justify-lg-content-center"}>
                  <div className="m-2 embed-responsive embed-responsive-16by9">
                    <iframe
                      poster={posterTwo}
                      className="rounded embed-responsive-item"
                      src="https://www.youtube.com/embed/eXmPzfIKkgw"
                      allowFullScreen
                    ></iframe>
                  </div>
                </Row>
                <Row>
                  <Col md={12} className={"text-left px-lg-0"}>
                    <p className="caption">
                      See for yourself what a closed door can do. The bedrooms
                      in this video are side by side, adjacent to the burning
                      living room.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container className="about-text pb-5">
          <Row className="pb-5">
            <Col md={{ span: 8, offset: 2 }}>
              <p>
                Why is the Close Your Door message so important now? Because
                fires have become more dangerous than ever.
              </p>
              <br></br>
              <p>
                Modern synthetic construction materials, home furnishings, and
                contemporary layouts allow fire to spread and become toxic much
                faster – like three minutes or less to get out faster.
              </p>
              <br></br>
              <p>
                But a closed door can slow the spread, reduce toxic smoke
                levels, improve oxygen levels and decrease temperatures
                dramatically – and that could make a life-saving difference in
                your home.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  </Layout>
)
